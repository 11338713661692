<template>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>活动管理</el-breadcrumb-item>
    </el-breadcrumb>
        <el-table
    :data="tableData"
    border
    style="width: 100%">

    <el-table-column
      prop="name"
      label="活动名称"
      width="120">
    </el-table-column>
    <el-table-column
      prop="photo"
      label="活动照片"
      width="220"
      >
      <template slot-scope="scope">
            <img
              :src="scope.row.photo"
              style="width: 200px; height: 200px"
            />
          </template>
    </el-table-column>
    <el-table-column
      prop="description"
      label="活动介绍"
      width="120">
    </el-table-column>
    <el-table-column
      prop="notice"
      label="活动须知"
      width="300">
    </el-table-column>

    <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
        <el-button type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>
</template>

<script>
  export default {
    methods: {
      handleClick(row) {
        console.log(row);
      }
    },

    data() {
      return {
        tableData: [
        {
          name: '黄山日出之旅',
          photo: 'http://120.24.253.55:9000/tengyuan/%E9%BB%84%E5%B1%B1.png?Content-Disposition=attachment%3B%20filename%3D%22%E9%BB%84%E5%B1%B1.png%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20240521%2F%2Fs3%2Faws4_request&X-Amz-Date=20240521T160134Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=7629a1d16dbef0d42707c5dd875e9ab8356c1b416d009a6fcd27cbb983cef0ef',
          description: '黄山，中国著名的风景名山，日出时分尤为壮观。',
          notice: '请提前预订门票，穿着舒适的徒步鞋。'
        },
        {
          name: '江南水乡游',
          photo: 'http://120.24.253.55:9000/tengyuan/%E6%B1%9F%E5%8D%97%E6%B0%B4%E4%B9%A1.png?Content-Disposition=attachment%3B%20filename%3D%22%E6%B1%9F%E5%8D%97%E6%B0%B4%E4%B9%A1.png%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20240521%2F%2Fs3%2Faws4_request&X-Amz-Date=20240521T160121Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=6d19770042d1cfcf3410e2fba8c88ccde9c3d90d390299c93bb65df2a27ead47',
          description: '体验江南水乡的宁静与美丽，乘坐乌篷船穿梭于古镇之间。',
          notice: '注意保持古镇环境清洁，尊重当地文化习俗。'
        },
        {
          name: '长城徒步探险',
          photo: 'http://120.24.253.55:9000/tengyuan/%E9%95%BF%E5%9F%8E.png?Content-Disposition=attachment%3B%20filename%3D%22%E9%95%BF%E5%9F%8E.png%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20240521%2F%2Fs3%2Faws4_request&X-Amz-Date=20240521T160107Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=778068597e353add06c0c63e78f96a93db89cac87a758ae3099c4fda3a963c67',
          description: '徒步长城，感受历史的厚重和壮丽的自然风光。',
          notice: '请携带足够的水和食物，注意防晒。'
        },
        // ... 其他7个活动的类似对象
      ]
      }
    }
  }
</script>

<style scoped>
.el-table{
  margin-top: 20px;
}
</style>
