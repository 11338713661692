<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column fixed prop="date" label="日期" width="150">
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="120"> </el-table-column>
      <el-table-column prop="province" label="省份" width="120">
      </el-table-column>
      <el-table-column prop="city" label="市区" width="120"> </el-table-column>
      <el-table-column prop="address" label="地址" width="300">
      </el-table-column>
      <el-table-column prop="zip" label="邮编" width="120"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >查看</el-button
          >
          <el-button type="text" size="small">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick(row) {
      console.log(row);
    },
  },

  data() {
    return {
      tableData: [
        {
          date: "2016-05-05",
          name: "李雷",
          province: "江苏",
          city: "南京",
          address: "江苏省南京市建邺区江东中路 100 号",
          zip: 210000,
        },
        {
          date: "2016-05-06",
          name: "韩梅梅",
          province: "浙江",
          city: "杭州",
          address: "浙江省杭州市西湖区文三路 200 号",
          zip: 310000,
        },
        {
          date: "2016-05-07",
          name: "陈浩",
          province: "四川",
          city: "成都",
          address: "四川省成都市武侯区人民南路 300 号",
          zip: 610000,
        },
        {
          date: "2016-05-08",
          name: "赵敏",
          province: "山东",
          city: "济南",
          address: "山东省济南市历下区经十路 400 号",
          zip: 250000,
        },
        {
          date: "2016-05-09",
          name: "钱多多",
          province: "河南",
          city: "郑州",
          address: "河南省郑州市金水区农业路 500 号",
          zip: 450000,
        },
        {
          date: "2016-05-10",
          name: "孙悦",
          province: "湖北",
          city: "武汉",
          address: "湖北省武汉市武昌区中山路 600 号",
          zip: 430000,
        },
        {
          date: "2016-05-11",
          name: "周杰",
          province: "湖南",
          city: "长沙",
          address: "湖南省长沙市岳麓区麓谷大道 700 号",
          zip: 410000,
        },
        {
          date: "2016-05-12",
          name: "吴亮",
          province: "陕西",
          city: "西安",
          address: "陕西省西安市雁塔区长安南路 800 号",
          zip: 710000,
        },
        {
          date: "2016-05-13",
          name: "郑强",
          province: "辽宁",
          city: "沈阳",
          address: "辽宁省沈阳市和平区青年大街 900 号",
          zip: 110000,
        },
      ],
    };
  },
};
</script>

<style scoped>
.el-table {
  margin-top: 15px;
}
</style>
