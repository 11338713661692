<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>打卡管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column fixed prop="activity" label="活动" width="150">
      </el-table-column>
      <el-table-column prop="attraction" label="景区" width="120">
      </el-table-column>
      <el-table-column prop="username" label="用户" width="120">
      </el-table-column>
      <el-table-column prop="time1" label="创建时间" width="120">
      </el-table-column>

      <el-table-column prop="time2" label="修改时间" width="120">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >查看</el-button
          >
          <el-button type="text" size="small">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick(row) {
      console.log(row);
    },
  },

  data() {
    return {
      tableData: [
        {
          activity: "春季赏花节",
          attraction: "樱花公园",
          username: "张三",
          time1: "2023-04-01T10:00:00",
          time2: "2023-04-01T14:00:00",
        },
        {
          activity: "古城徒步游",
          attraction: "丽江古城",
          username: "李四",
          time1: "2023-05-15T08:30:00",
          time2: "2023-05-15T12:00:00",
        },
        {
          activity: "夏日海滩派对",
          attraction: "三亚亚龙湾",
          username: "王五",
          time1: "2023-07-20T15:00:00",
          time2: "2023-07-20T20:00:00",
        },
        // ... 重复上述结构以生成更多条目 ...
        {
          activity: "冬季滑雪体验",
          attraction: "长白山滑雪场",
          username: "赵六",
          time1: "2023-12-9T09:00:00",
          time2: "2023-12-9T13:00:00",
        },
      ],
    };
  },
};
</script>

<style scoped>
.el-table{
  margin-top: 20px;
}
</style>
