import axios from 'axios';

// 创建axios实例
const http = axios.create({
  // baseURL: 'http://localhost:9604', 
  baseURL: "https://api.travelclock.tengyuan.site",
// API的baseURL
  timeout: 1000 // 请求超时时间
});

// 请求拦截器
http.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    const token = localStorage.getItem('token'); // 假设token存储在localStorage中
    if (token) {
      config.headers['token'] = `${token}`; // 假设后端需要Bearer Token
    }
    // 打印请求的详细信息
    console.log('token:', token);
    console.log('请求的URL:', config.url);
    console.log('请求的方法:', config.method);
    console.log('请求的headers:', config.headers);
    console.log('请求的参数:', config.params);
    console.log('请求的body:', config.data);

    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  response => response,
  error => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

// 导出axios实例
export default http;