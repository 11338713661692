<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="SearchInput"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="AddDialogVisible = true"
            >添加课程</el-button
          >
        </el-col>
      </el-row>
      <!-- 课程列表 -->
      <el-table :data="Course" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column
          label="课程名"
          prop="name"
          width="120px"
        ></el-table-column>
        <el-table-column
          label="课程简介"
          prop="courseIntro"
          width="120px"
        ></el-table-column>
        <el-table-column label="课程封面" width="120px">
          <template slot-scope="scope">
            <img
              :src="scope.row.courseCover"
              style="width: 100px; height: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column label="视频" prop="video">
          <template slot-scope="scope">
            <video
              :src="scope.row.videoPath"
              controls
              width="250px"
              height="120px"
            ></video>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100px" prop="invokeFlag">
          <template slot-scope="scope">
            <span v-if="scope.row.invokeFlag === 1">启用</span>
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170px">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editCourse(scope.row)"
              >
              </el-button>
            </el-tooltip>

            <el-tooltip effect="dark" content="删除" placement="top">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
          <!-- 分配角色 -->
          <!-- <el-button type="warning" icon="el-icon-setting" size="mini"></el-button> -->
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!-- 添加课程弹窗 -->
      <el-dialog
        title="添加课程"
        :visible.sync="AddDialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="CourseList" label-width="100px">
          <el-form-item label="课程名">
            <el-input v-model="CourseList.name"></el-input>
          </el-form-item>
          <el-form-item label="课程简介">
            <el-input v-model="CourseList.intro"></el-input>
          </el-form-item>
          <el-form-item label="课程封面">
            <el-upload
              class="avatar-uploader"
              :headers="headers"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="视频链接">
            <el-upload
              class="upload-demo"
              drag
              :headers="headers"
              :action="uploadUrl"
              :before-upload="beforeUploadVideo"
              :on-success="handleSuccess"
              :on-error="handleError"
              :multiple="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将课程视频文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传视频文件，且不超过2GB
              </div>
            </el-upload>
          </el-form-item>
          <!-- <el-form-item label="课程状态">
            <el-switch v-model="CourseList.status"></el-switch>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="AddDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAddCourse">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 添加编辑弹窗 -->
      <el-dialog
        title="编辑课程"
        :visible.sync="UpdateDialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="UpdateCoure" label-width="100px">
          <el-form-item label="课程名">
            <el-input v-model="UpdateCoure.name"></el-input>
          </el-form-item>
          <el-form-item label="课程简介">
            <el-input v-model="UpdateCoure.courseIntro"></el-input>
          </el-form-item>
          <el-form-item label="课程封面">
            <el-upload
              class="avatar-uploader"
              :headers="headers"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="updateImageUrl" :src="updateImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="视频链接">
            <el-upload
              class="upload-demo"
              drag
              :headers="headers"
              :action="uploadUrl"
              :before-upload="beforeUploadVideo"
              :on-success="handleSuccess"
              :on-error="handleError"
              :multiple="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将修改的课程视频文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传视频文件，且不超过2GB
              </div>
              
            </el-upload>
            <div>视频预览</div>
            <video :src="this.UpdateCoure.videoPath" class="custom-video-size" controls></video>

          </el-form-item>
          <!-- <el-form-item label="课程状态">
            <el-switch
              v-model="UpdateCoure.invokeFlag"
              active-value="1"
              inactive-value="0"
            ></el-switch>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="UpdateDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmUpdateCourse"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SearchInput: "",
      currentPage4: 1,
      CourseList: {
        id: 0,
        name: "",
        image: "",
        video: "",
        status: false,
        intro: "",
      },
      AddDialogVisible: false,
      UpdateDialogVisible: false,
      searchParams: {
        pageNumber: 1,
        pageSize: 10,
        name: null,
        courseType: null,
        invokeFlag: 1,
      },
      Course: [],
      imageUrl: "",
      updateImageUrl: "",
      headers: {
        token: localStorage.getItem("token"),
      },
      uploadUrl: "https://api.travelclock.tengyuan.site/api/file/uploadOss",
      UpdateCoure: {
        invokeFlag: "1",
      },
      total: 10,
    };
  },
  created() {
    this.fetchData(this.searchParams);
  },
  methods: {
    handleSizeChange(newSize) {
      this.searchParams.pageNumber = 1; // 通常分页大小变化时，会回到第一页
      this.searchParams.pageSize = newSize;
      (this.searchParams.name = this.SearchInput),
        this.fetchData(this.searchParams);
    },
    handleCurrentChange(newPage) {
      this.searchParams.pageNumber = newPage;
      this.fetchData(this.searchParams);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    fetchData(params) {
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      this.$http
        .post("/api/course/pageSearch", params, headers)
        .then((response) => {
          // 处理响应
          this.Course = response.data.data.data;
          console.log("接收到的", this.Course);
          this.total = response.data.data.total;
          console.log("总条数", this.total);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    handleDelete(row) {
      console.log("要删除的课程ID:", row.id);
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        idList: [row.id],
      };
      this.$http
        .post("/api/course/delete", params, headers)
        .then((response) => {
          // 处理响应
          this.fetchData(this.searchParams);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    handleAvatarSuccess(res, file) {
      //接收服务器返回的图片地址
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.data) {
        this.CourseList.image = res.data;
        this.UpdateCoure.courseCover = res.data;
        this.updateImageUrl = res.data;
        console.log("图片地址", this.UpdateCoure.courseCover);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const acceptedMimes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!acceptedMimes.includes(file.type)) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 或 WEBP 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      // return isJPG && isLt2M;
      return acceptedMimes.includes(file.type) && isLt2M;
    },
    beforeUploadVideo(file) {
      // 检查文件类型是否为视频
      const videoTypes = ["video/mp4", "video/avi", "video/mov", "video/wmv"];
      if (!videoTypes.includes(file.type)) {
        this.$message.error("不支持的文件类型，请选择视频文件上传。");
        return false;
      }

      // 检查文件大小，限制大小为10GB
      const maxSize = 2 * 1024 * 1024 * 1024; // 10GB in bytes
      if (file.size > maxSize) {
        this.$message.error("视频文件太大，不能超过2GB。");
        return false;
      }

      // 如果文件类型和大小检查通过，则返回 true，允许上传
      return true;
    },
    handleSuccess(response, file, fileList) {
      // 文件上传成功的处理逻辑
      // 接收服务器返回的视频地址
      if (response.data) {
        this.UpdateCoure.videoPath = response.data;
        this.CourseList.video = response.data;
        console.log("视频地址", this.UpdateCoure.videoPath);
      }
    },
    handleError(error, file, fileList) {
      // 文件上传失败的处理逻辑
      // 打印错误对象
      console.error("上传错误:", error);

      // 尝试提取错误信息并显示
      let errorMessage = "上传失败";
      if (error.response && error.response.data) {
        // 假设服务器返回的错误信息在 data.message 中
        errorMessage = error.response.data.message || errorMessage;
      }

      // 使用 Element UI 的 message 组件显示错误提示
      this.$message.error(errorMessage);
    },
    confirmAddCourse() {
      console.log("新增视频==", this.CourseList);
      //关闭弹窗
      this.AddDialogVisible = false;
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };

      let status = 0;
      if (this.CourseList.status) {
        status = 1;
      }
      const params = {
        videoPath: this.CourseList.video,
        invokeFlag: 1,
        name: this.CourseList.name,
        courseType: 0,
        courseCover: this.CourseList.image,
        courseIntro: this.CourseList.intro,
      };
      this.$http
        .post("/api/course/insert", params, headers)
        .then((response) => {
          // 处理响应
          response.data.data.data;
          console.log("接收到的", this.Course);
          this.fetchData(this.searchParams);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    //将修改数据发送给服务器
    confirmUpdateCourse() {
      this.UpdateDialogVisible = false;

      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      let status = 0;
      if (this.UpdateCoure.status) {
        status = 1;
      }
      const params = {
        id: this.UpdateCoure.id,
        // invokeFlag: status,
        invokeFlag: null,
        name: this.UpdateCoure.name,
        courseType: 0,
        courseCover: this.UpdateCoure.courseCover,
        courseIntro: this.UpdateCoure.courseIntro,
        videoPath: this.UpdateCoure.videoPath,
      };
      this.$http
        .post("/api/course/update", params, headers)
        .then((response) => {
          // 处理响应
          this.fetchData(this.searchParams);
          console.log("接收到的", this.Course);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    // 点击编辑按钮 打开编辑弹窗
    editCourse(course) {
      // 设置 CourseList 为当前课程的数据，以便在编辑表单中显示
      this.UpdateCoure = { ...course };
      if (this.UpdateCoure.courseCover) {
        this.updateImageUrl = this.UpdateCoure.courseCover;
        // this.UpdateCoure.courseCover = this.updateImageUrl;
      }
      // 打开编辑对话框
      this.UpdateDialogVisible = true;
      console.log("状态值：====", this.UpdateCoure.invokeFlag);
    },
    search() {
      this.searchParams.name = this.SearchInput;
      this.fetchData(this.searchParams);
    },
  },
};
</script>

<style scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 12px;
}

.el-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
}

.el-table {
  margin-top: 15px;
  font-size: 12px;
}

.el-pagination {
  margin-top: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.custom-video-size{
  width: 400px; /* 或者使用百分比，例如: 100% */
  height: 200px; /* 同样，也可以使用百分比 */
}
</style>

