<template>
  <div class="container">
    <div class="LandingModule">
      <div class="image">
        <img src="../assets/login.png" />
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login('ruleForm')">登陆</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        pass: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "长度在 3 到 12 个字符",
            trigger: "blur",
          },
        ],
        pass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "长度在 3 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
      url: '',
    };
  },
  computed: {
  },
  methods: {
    ...mapGetters([
      'getBaseURL', // 映射 Vuex getter 到计算属性
    ]),
    login(formName) {
      // this.$router.push('/home');
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loginData = {
            userNo: this.ruleForm.name,
            userPsw: this.ruleForm.pass,
          };
          axios
            .post("https://api.travelclock.tengyuan.site/api/user/login", loginData, {
              headers: {
                "Content-Type": "application/json", // 显式设置请求头为 JSON
              },
            })
            .then((response) => {
              // 请求成功
              const result = response.data;
              if (result.code === 200) {
                // 登录成功
                localStorage.setItem("token", result.data);
                console.log('打印token',result.data);
                // this.$message({
                //   message: "恭喜你，登陆成功",
                //   type: "success",
                // });
                // 跳转
                this.$router.push("/course");
              } else {
                // 登录失败，显示错误信息
                // this.$message.error(result.message);
                console.error("登录失败：", result.message);
              }
            })
            .catch((error) => {
              // 请求失败
              console.error("请求错误：", error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

  },
  mounted() {

  },
  
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f6f9;
  width: 100%;
  height: 100vh;
}
.LandingModule {
  width: 400px;
  margin-top: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  margin-top: 30px;
}
.image img {
  width: 40px;
  height: 40px;
}

.el-form {
  margin-top: 15px;
}
</style>