<template>
  <el-container class="home-container">
    <el-header>
      <div class="icon">
        <img src="../assets/lvyou.png" />
      </div>
      <div class="text1">旅游APP后台管理系统</div>
      <div class="exit-button">
        <el-button type="info" @click="exit">退出</el-button>
      </div>
    </el-header>

    <el-container>
      <el-aside width="200px">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="1" @click="navigateTo('/course')">
            <i class="el-icon-video-camera"></i>
            <span slot="title">课程管理</span>
          </el-menu-item>
          <el-menu-item index="2" @click="navigateTo('/user')">
            <i class="el-icon-s-custom"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item index="3" @click="navigateTo('/scenery')">
            <i class="el-icon-setting"></i>
            <span slot="title">景点管理</span>
          </el-menu-item>
          <el-menu-item index="4" @click="navigateTo('/activity')">
            <i class="el-icon-setting"></i>
            <span slot="title">活动管理</span>
          </el-menu-item>
          <el-menu-item index="5" @click="navigateTo('/clock')">
            <i class="el-icon-setting"></i>
            <span slot="title">打卡管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    navigateTo(route) {
      if (this.$route.path === route) {
        console.log("当前路由已经是", route);
      } else {
        // 如果路由不同，正常导航
        this.$router.push(route);
      }
    },
    exit() {
      console.log("退出按钮被点击");
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.home-container {
}
.el-header {
  background-color: #3e4548;
  display: flex;
  align-items: center;
}
.el-aside {
  background-color: #383f4c;
}
.el-main {
  background-color: #ebeff2;
}
.home-container {
  height: 100%;
}

.icon img {
  width: 50px;
}

.text1 {
  color: #fff;
  margin-left: 15px;
}

.exit-button {
  margin-left: auto;
}
</style>