import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'
import Welcome from '@/components/Welcome.vue'
import Course from '@/components/Course.vue'
import User from '@/components/User.vue'
import Scenery from '@/components/Scenery.vue'
import Activity from '@/components/Activity.vue'
import ClockIn from '@/components/ClockIn.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login', // 访问路径
    component: Login // 对应的组件
  },
  {
    path: '/home', // 访问路径
    component: Home, // 对应的组件
    redirect: '/welcome',
    children:[
      { path: '/welcome',component: Welcome},
      { path: '/course',component: Course },
      { path: '/user',component: User },
      { path: '/scenery',component: Scenery },
      { path: '/activity',component: Activity },
      { path: '/clock',component: ClockIn },

  ]
  }
]

const router = new VueRouter({
  routes
})
export default router
