<template>
    <div>
            <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>景点管理</el-breadcrumb-item>
    </el-breadcrumb>
        <el-table
    :data="tableData"
    border
    style="width: 100%">

    <el-table-column
      prop="name"
      label="景点名称"
      width="120">
    </el-table-column>
    <el-table-column
      prop="province"
      label="景点介绍"
      width="120">
    </el-table-column>
    <el-table-column
      prop="city"
      label="景点纬度"
      width="120">
    </el-table-column>
    <el-table-column
      prop="address"
      label="景点经度"
      width="300">
    </el-table-column>
    <el-table-column
      prop="zip"
      label="景点图片"
      width="220"
      hidden="200">
      <template slot-scope="scope">
            <img
              :src="scope.row.zip"
              style="width: 200px; height: 200px"
            />
          </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
        <el-button type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>
</template>

<script>
 export default {
    methods: {
      handleClick(row) {
        console.log(row);
      }
    },

    data() {
      return {
        tableData: [{
          date: '2016-05-02',
          name: '西湖',
          province: '三潭印月是杭州西湖中部偏南的赏月胜地',
          city: '19.8',
          address: '19.8',
          zip: "https://echengtuhua.oss-cn-shenzhen.aliyuncs.com/%E6%99%AF%E7%82%B9%E5%9B%BE.png",
        },
        {
          date: '2016-05-02',
          name: '长城',
          province: '八达岭长城，位于北京市延庆区军都山关沟古道北口。',
          city: '19.8',
          address: '19.8',
          zip: "http://120.24.253.55:9000/tengyuan/%E9%95%BF%E5%9F%8E.png?Content-Disposition=attachment%3B%20filename%3D%22%E9%95%BF%E5%9F%8E.png%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20240521%2F%2Fs3%2Faws4_request&X-Amz-Date=20240521T153033Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=ec1051b178a8bfd8f2b3ea355d14718776c9c06b627a6ba2b054f031f1b8e51c",
        },
        {
          date: '2016-05-02',
          name: '兵马俑',
          province: '兵马俑，即秦始皇陵兵马俑，亦简称秦兵马俑或秦俑，是第一批全国重点文物保护单位、第一批中国世界遗产，',
          city: '19.8',
          address: '19.8',
          zip: "http://120.24.253.55:9000/tengyuan/%E5%85%B5%E9%A9%AC%E4%BF%91.png?Content-Disposition=attachment%3B%20filename%3D%22%E5%85%B5%E9%A9%AC%E4%BF%91.png%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20240521%2F%2Fs3%2Faws4_request&X-Amz-Date=20240521T153320Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=1b0c1fb54646b2478390d5d3b6a2512a402ac3fb6883b9f2890626637233861f",
        }
    ]
      }
    }
  }
</script>


<style scoped>
.el-table{
    margin-top: 20px;
}
</style>